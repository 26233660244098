import PropTypes from "prop-types";
import React from "react";
import { Link } from "gatsby";

function Layout({ children }) {
  return (
    <div className="flex flex-col min-h-screen font-sans text-black bg-brandgray-100">
      <main className="flex flex-col w-full max-w-4xl m-auto py-8">
        {children}
      </main>

      <footer className="bg-brandgray-200">
        <nav className="text-center max-w-4xl p-4 mx-auto text-sm">
          <p className="text-white">
            <Link
              className="font-light text-black hover:text-brandred-900 transition-colors no-underline px-4"
              to="/impressum"
            >
              Impressum
            </Link>
            <Link
              className="font-light text-black hover:text-brandred-900 transition-colors no-underline px-4"
              to="/datenschutz"
            >
              Datenschutz
            </Link>
          </p>
        </nav>
      </footer>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
